import React, {Suspense, useEffect} from 'react';

function CursorBlinking() {
    const [on, setOn] = React.useState(false);

    useEffect(() => {
        const t = setInterval(() => {
            setOn(i => !i);
        }, 300);
        return () => clearInterval(t);
    },[]);
    return (
        <span style={{display: on ? 'none': 'inline'}}>|</span>
    );
}

export default CursorBlinking;
