import React, {Suspense} from 'react';
import {Canvas, useFrame, useLoader, useThree} from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import styled from "styled-components";
import BlinkingCursor from "./blinkcursor.js";
function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}
/*
<pointLight position={[2, 5, -1.2]} color={0xFFF} distance={4.82} intensity={3.25}/>

<pointLight position={[2.1, 0.83, 0]} color={0xFFF} distance={4} intensity={2.6}/>

<pointLight position={[-1.67, 0.35, 0]} color={0xFFF} distance={5} intensity={2.2}/>

<pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF} distance={4.4} intensity={2.6}/>
 */

const Title = styled.h1`
 color: white;
 size: 2rem;
 position: absolute;
 left: 2rem;
 top: 2rem;
`;

const Text = styled.p`
 position: absolute;
 top: 6rem;
 left: 2rem;
 width: 200px;
 color: white;
`;

const B = styled.b`
 size: 1rem;
 fontWeight: 'bold'
 top: 8rem;
 left: 2rem;
 width: 200px;
 color: white;
`;

const TheScene = ({next, }) => {

    const [xy, setXY] = React.useState([0,0]);
    const { clock } = useThree();

    useFrame(() => {

        setXY([Math.cos(clock.oldTime / 1000) * 2.5, Math.sin(clock.oldTime / 1000) * 2.5]);
        if(clock.oldTime % 100) {
            console.log(clock);
        }

    })
    return  <scene>

        <pointLight position={[xy[0], xy[1], 4]} color={"#4D4D4D"} distance={12} intensity={1.5}/>
        <group position={[0,-2.5,0]} scale={[0.4,0.4,0.4]}>
            <Suspense fallback={null}>
                <Asset onClick={next} url="/model/medusasmaller.glb" />
            </Suspense>
        </group>

    </scene>
}

const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function App() {
  return (
    <div className="App">
      <Canvas style={{ height: "100vh", width: "100vw", background: "black"}}>
          <scene>
              <TheScene onClick={() => {}}/>
          </scene>
      </Canvas>
        <Title>haram.club<BlinkingCursor/></Title>
        <Text>Haram Hosting Ltd.<br/>
	Suite 9, Ansuya Estate Revolution Avenue<br/>
	Mahe<br/>
	Seychelles<br/><br/>
     <B>abuse [at] haram.club</B></Text>
    </div>
  );
}

export default App;
